ion-toolbar, ion-header {
    --background: #7b8794;
    --min-height:50px;
    --border-width:0 !important;
}
ion-title {
  --color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}
.back-button {
	background: transparent;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
  z-index: 999;
}
.login-box {
    padding: 30px;
    background: #3e4c59;
    border-radius: 5px;
}
.login-box h1 {
    color: #ffffff;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
}
.login-box h1 span {
    font-weight: 400;
    font-size: 16px;
    display: block;
    line-height: normal;
    margin-top: 10px;
}
.login-box img {
    height: 80px;
    width: auto;
    margin: auto auto 1rem auto;
    display: block;
}
.support-by-limerr {
    position: fixed;
    right: 20px;
    bottom: 20px;
    color: #ffffff;
    font-size: 14px;
}
.form-group {
    margin-bottom: 20px;
}
.support-by-limerr a {
    color: #f76a2d;
    text-decoration: underline;
}
.support-by-limerr span {
    display: inline-block;
    width: 200px;
    vertical-align: middle;
    margin-right: 20px;
    text-align: right;
    line-height: 26px;
}
.powered-by-limerr {
    position: fixed;
    left: 20px;
    bottom: 20px;
}
.powered-by-limerr>a {
    display: block;
}
.powered-by-limerr img {
    height: 20px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.ng-select .ng-select-container {
    min-height:46px;
    background: #52606d;
    border: 1px solid #52606d;
    color: #ffffff;
    border-radius: 5px;
    font-size: 1rem;
}
.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 15px;
    font-weight: 400;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #9aa5b1;
}
.footer {
	padding: 5px;
	text-align: center;
	position: fixed;
	bottom: 0;
	width: 100%;
	background: #1f2933;
	font-size: 13px;
	line-height: normal;
}

.footer .footer-text {
    display: inline-block;
    margin: 0px 4px;
}

.app-content {
    padding-top: 50px;
}

.main-content {
    padding:20px;
    height: 100%;
}

.custom-grid {
    display: block;
    margin: 0;
}

.custom-grid-column {
    width: 140px;
    height: 130px;
    margin: 0 15px 15px 0px;
    align-items: center;
    border: 0px;
    border-radius: 5px;
    vertical-align: top;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: normal;
    padding: 0 5px;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    position: relative;
}

.custom-grid-column .box-icons {
    margin-bottom: 10px;
}

.custom-grid-column .box-icons img {
    height: 45px;
    width: auto;
    display: block;
    margin: 0 auto;
}


.modal-yes-now {
    padding: 20px;
    text-align: center;
}

.modal-yes-now .icon {
    width: 80px;
    margin: auto;
}

.modal-yes-now h4 {
    color: #ffffff;
    margin: 20px 0 30px 0;
    line-height: 36px;
    font-size: 22px;
    font-weight: 400;
}

.page-heading {
    margin-bottom:1rem;
}


.attendance-tabs .nav-tabs {
    margin:0 0 20px 0;
    padding: 0;
}

.attendance-tabs .nav-tabs .nav-item {
    margin: 0px 20px 0px 0px;
}


.attendance-tabs .nav-tabs .nav-link span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.attendance-tabs .nav-tabs .nav-link span img {
    display: block;
    margin: auto;
    height: 30px;
}
.checkout-filter {
    display: inline-block;
    vertical-align: top;
}

.active-user {
    color: #19d895;
}

.change-user {
    color: #19d895;
}

.form-box {
	background: #3e4c59;
	padding: 30px;
	border-radius: 5px;
}

.orderlisting-tabs {
    background: #52606d;
    padding: 10px;
}
.orderlisting-tabs .nav-tabs {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.orderlisting-tabs .nav-tabs .nav-item {
	margin-right: 10px;
	position: relative;
}
.orderlisting-tabs .nav-tabs .nav-item:last-child {
    margin-right: 0px;
}
.orderlisting-tabs .nav-tabs .nav-link {
    background: #7b8794;
    font-size: 14px;
    text-align: center;
    padding: 7px 0px;
    line-height: normal;
    font-weight: 500;
    width: 100%;
    display: block;
}

.orderlisting-tabs .nav-tabs .nav-item.show .nav-link,
.orderlisting-tabs .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #19d895;
    border-color: #19d895;
}
.table-filter {
    position: relative;
}
.table-filter .tickmark {
    width: 15px;
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: 0;
}
.table-filter.ticked .tickmark {
    opacity: 1;
}
.orderlisting-tabs .nav-tabs .nav-link span {
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.orderlisting-tabs .nav-tabs .btn {
    padding: 9px 0px;
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

.pos-dashboard .tab-content {
    padding:5px;
}
.order-box {
    padding: 10px 60px 10px 90px;
    position: relative;
    background: #3e4c59;
    margin-bottom: 10px;
    border-radius: 5px;
    min-height: 80px;
    display: grid;
}
.order-box.selected {
    background: #616e7c;
}
.order-platform-logo {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #ffffff;
    overflow: hidden;
    margin: auto;
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
}
.order-platform-logo span {
    font-size: 18px;
    color: #121212;
    text-align: center;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 60px;
    font-weight: 500;
}
.order-platform-logo img {
    margin: 0 auto;
    display: block;
}
.customer-order-detail {
    font-size: 15px;
    line-height: 24px;
}
.customer-order-detail span {
    color: #ffffff;
}
header#header {
    /* background: #52606d; */
    padding:8px 20px;
    line-height: normal;
}
header .logo-icon {
    padding:8px 20px;
    background: #52606d;
}
.conditional-height {
    min-height: 75px;
}
.conditional-position {
    top: 35px;
}
.store-logo {
    float: left;
    margin: 5px 0;
}
.store-logo img {
    height: 26px;
    width: auto;
}
.rightlinks {
    float: right;
    line-height:30px;
    letter-spacing: 1px;
}
.rightlinks>a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    position: relative;
}
.rightlinks>a:hover,
.rightlinks>a:focus {
    color: #ffffff;
}
.rightlinks>a>.counter {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #ff0000;
    border-radius: 50%;
    right: -5px;
    top: -5px;
}
.offline-sign .blink {
    background-color: #f0595f !important;
  }
  .online-sign {
    width:26px;
    height:26px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: 4px;
    padding: 3px;
}
.online-sign .blink img {
    display: block;
}
.online-sign .blink {
    display: block;
  width:20px;
  height:20px;
  background-color: #0fcc45;
  opacity:1;
  border-radius: 50%;
  animation: blinker 1s step-end infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .internet {
    background: #f77268;
    height: auto;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    padding: 6px 0;
    line-height: 10px;
    top: 0;
    left: 0;
    margin: -7px -20px 0px -20px;
    z-index: 9;
}

.internet-text {
    animation: blink 6s infinite;
}

@keyframes blink {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

.disabled {
    opacity: 0.4;
}

.notfound {
    text-align: center;
    position: absolute;
    height: 130px;
    font-size: 16px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    color: #ffffff;
}

.notfound span img {
    height:90px;
    width: auto;
    display: block;
    margin: auto auto 1rem auto;
}
.notfound-customer-lookup {
    position: relative;
    padding:8% 0;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    background: #3e4c59;
    border-radius: 5px;
}
.notfound-customer-lookup span img {
    height:90px;
    width: auto;
    display: block;
    margin: auto auto 1rem auto;
}
.ngx-pagination {
    padding: 10px;
    text-align: right;
    margin: 0 !important;
}

.ngx-pagination .disabled {
	padding:10px 20px !important;
	color: #ffffff !important;
	background: #52606d !important;
	margin-left: 10px;
	border-radius: 5px;
	font-size: 14px;
	line-height: 24px;
}
.ngx-pagination li {
	display: none !important;
}
.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next,
.ngx-pagination .current {
	display:inline-block !important; 
}
.ngx-pagination li.pagination-previous>a {
    padding: 10px 20px !important;
    background: #52606d !important;
    color: #fefefe;
    margin-left: 10px;
    border-radius: 5px;
    text-decoration: none;
}
.ngx-pagination .pagination-next>a {
    padding:10px 20px !important;
    background: #19d895 !important;
    color: #fefefe;
    margin-left: 10px;
    border-radius: 5px;
    text-decoration: none;
}

.ngx-pagination .current {
	padding:10px 20px !important;
	background: #52606d !important;
	color: #fefefe;
	margin-left: 10px;
	border-radius: 5px;
}

.payment-type-icon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    width: 40px;
}

.payment-type-icon img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
}

/* ORDER DETAIL MODAL POPUP */
.modal-order-detail {
    position: fixed;
    margin: auto;
    top: 0px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0) !important;
    ;
    -ms-transform: translate3d(0%, 0, 0) !important;
    ;
    -o-transform: translate3d(0%, 0, 0) !important;
    ;
    transform: translate3d(0%, 0, 0) !important;
    right: -600px;
    max-width: 600px;
    width: 100%;
    -webkit-transition: opacity 0.5s linear, right 0.4s ease-out;
    -moz-transition: opacity 0.5s linear, right 0.4s ease-out;
    -o-transition: opacity 0.5s linear, right 0.4s ease-out;
    transition: opacity 0.5s linear, right 0.4s ease-out;
}

.modal.fade .modal-order-detail {
    -webkit-transition: .4s ease-out !important;
    -o-transition: .4s ease-out !important;
    transition: .4s ease-out !important;
}

.modal.show .modal-order-detail {
    right: 0;
}

.modal-order-detail .modal-content {
    height: 100%;
    border-radius: 0;
}

.modal-order-detail .order-basic-information {
	padding: 10px 10px 0px 10px;
	background: #616e7c;
	line-height: normal;
}
.modal-order-detail .order-basic-information .order-label {
    font-size: 14px;
    margin-bottom: 5px;
}

.modal-order-detail .order-basic-information .order-value {
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 14px;
}

.modal-order-detail .order-calculation-section {
	padding: 10px;
	position: absolute;
	bottom: 0;
	right: 0;
	background: #323f4b;
	width: 100%;
	left: 0;
}
.modal-order-detail .action-buttons {
    margin-top: 15px;
}

.modal-order-detail .action-buttons .btn-action {
    border-radius: 5px;
    font-weight:500;
    line-height: normal;
    height: 50px;
    width: auto;
    display: inline-block;
    padding: 0 20px;
    margin-left: 10px;
    font-size: 14px;
    letter-spacing: 0.5px;
}
.modal-order-detail .action-buttons .btn-action img {
    height: 20px;
    margin-bottom: 5px;
}

.custom-print-button span {
    display: block;
}

.order-detail-calculations {
	line-height:30px;
    color: #ffffff;
}

.order-detail-calculations span {
    font-weight: 500;
}

.table-fixed tbody {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.table-fixed thead,
.table-fixed tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.table-fixed thead {
    position: relative;
}

.button-action-grid {
    padding: 10px 5px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #1f2933;
    left: 0;
}

.button-action-grid .button-action {
    padding: 8px;
    text-transform: capitalize;
    line-height: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.button-action-grid .button-action span {
    display: block;
    margin-bottom: 6px;
}

.button-action-grid .button-action span img {
    height: 25px;
    width: auto;
    display: block;
    margin: auto;
}

.button-action-grid ul {
    list-style: none;
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-action-grid ul li {
    width: 100%;
    padding: 0px 5px;
}

.paidout-expense-grid-header {
    padding: 15px 20px;
    border-radius: 5px;
    background: #52606d;
    margin-bottom: 5px;
}

.paidout-expense-grid-header h6 {
    color: #ffffff;
    margin: 0;
}
.paidout-expense-grid-body {
	/* max-height: calc(100vh - 260px);
	overflow-y: auto; */
}
.paiout-expense-box {
    background: #3e4c59;
    border-radius: 5px;
    margin-bottom: 5px;
    padding:14px 20px;
}

.paiout-expense-box:nth-child(even) {
    /* background: #616161 */
}
.add-item-name {
	line-height: 20px;
	color: #19d895;
	font-size: 13px;
	position: relative;
}

.add-item-name .add-item-price {
    display: inline-block;
}

.remove-item-name {
    line-height: 20px;
    color: #ff8181;
    font-size: 13px;
    position: relative;
}

.remove-item-name .remove-item-price {
    display: inline-block;
}
span.hightlight-icon {
    position: absolute;
    background: #e36363;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.page-pos-leftsidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 32%;
    background: #3e4c59;
    z-index: 1;
}
.page-pos-rightsidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 68%;
    padding-right: 180px;
}
.page-checkout .page-pos-rightsidebar {
    padding-right: 0;
}
.nav-scroller-item {
	padding:5px;
	font-size: 14px;
	text-align: center;
	background: #7b8794;
	color: #ffffff;
	line-height: 22px;
	margin: 0px 0px 10px 0px;
	border-radius: 5px;
	width: 100%;
	justify-content: center;
	height: 60px;
}
.nav-scroller-item.selected {
    border-color: #19d895;
    background: #19d895;
    color: #ffffff;
}
.custom-page-controls {
    /* position: absolute;
    left: 0;
    width: 100%;
    bottom: 96px;
    padding-left: 10px; */
}
.custom-page-controls>div>button {
    border: 1px solid #9aa5b1;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    text-align: center;
    width: 100%;
    height:58px;
    border-radius: 5px;
    background: #9aa5b1;
    justify-content: center;
    align-items: center;
}

.custom-page-controls>div.disabled>button {
    border-color: #9aa5b1;
    pointer-events: none;
    background: #9aa5b1;
}

.product-sub-categories {
    background: #3e4c59;
    padding: 10px;
}
.sub-cat-controls button {
    background: #9aa5b1;
    display: flex;
    border-radius: 5px;
    height: 42px;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.sub-nav-scroller {
    color: #ffffff;
    font-weight: 400;
    margin: 0;
    height: 42px;
    font-size: 14px;
    line-height: normal;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    padding: 0 5px;
    background: #7b8794;
}
.modal-search-plu .productbox {
    padding: 10px 20px;
}
.productbox {
    display: grid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    height: 100px;
    cursor: pointer;
    line-height: 20px;
    position: relative;
    background: #52606d;
    justify-content: center;
    width: 100%;
    align-items: center;
}
.btn-order-type img {
	height: 14px;
	width: auto;
	display: inline-block;
	vertical-align: middle;
}
.productbox.select-product {
    background: #7b8794;
}
.productbox .item-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.product-item-qty {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    width: 28px;
    text-align: center;
    height: 28px;
    line-height: 28px;
    background: #ff6258;
    border-radius: 10px 0px 0px 0px;
}
.product-item-price {
    color: #ffffff;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
}
.product-item-select {
    color: #ffffff;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
}
.productbox .plu_type_icon {
    height:8px;
    width:8px;
    display: block;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 5px;
}

.plu_type_veg {
    background: #19d895;
}

.plu_type_nonveg {
    background: #ff6258;
}

.plu_type_egg {
    background: #ffaf00;
}

.pos-order-total {
    background: #52606d;
    padding: 10px;
    color: #ffffff;
    line-height: normal;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 86px;
    min-height: 102px;
    display: grid;
}

.pos-order-total span {
    display: block;
    font-size: 22px;
    font-weight: 600;
    margin-top: 4px;
}

.pos-customer-info {
    background:#616e7c;
    padding: 10px;
}

.btn-order-type {
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 5px;
    font-weight: 400;
    border: 0;
    height: auto;
    background: #8b8b8b;
    text-transform: uppercase;
}

.order-customer-name {
    font-size: 15px;
    padding-left: 50px;
    line-height: normal;
    cursor: pointer;
}

.order-customer-name span {
    display: block;
    font-weight: 500;
    color: #ffffff;
}

.customer-search-icon {
	position: absolute;
	left:10px;
	top: 0;
	bottom: 0;
	margin: auto;
	width:30px;
	height:30px;
}

.btn-remove {
    margin: 3px 0px;
    padding: 0;
    line-height: normal;
    width: 20px;
    height: 20px;
    background: transparent;
}

.btn-remove img {
    vertical-align: top;
    display: inline-block;
}

.coupon-code-strip {
    position: relative;
    line-height: 22px;
}

.coupon-code-label {
    display: inline-block;
    vertical-align: top;
    color: #19d895;
    font-weight: 500;
}

.coupon-code-amount {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 16px;
    font-weight: 700;
}

.coupon-code-strip .remove-coupon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    height: 22px;
    bottom: 0;
    cursor: pointer;
}

.coupon-code-strip .remove-coupon img {
    height: 100%;
    width: auto;
    display: block;
}

.coupon-type {
    display: block;
    position: relative;
}

.coupon-type .checkmark {
    display: none;
}

.coupon-type.selected .checkmark {
    position: absolute;
    right: 5px;
    top: 5px;
    display: block;
}

.coupon-type.selected .checkmark img {
    width: 20px;
}

.coupon-type input[type=radio].coupon-radio {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.coupon-type input[type=radio].coupon-radio+label,
.coupon-type input[type=radio].coupon-radio+label.clr {
    display: block;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 20px 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    border: 0 !important;
    font-weight: 400;
    font-size: 16px;
    background: #52606d;
    color: #ffffff;
}

.coupon-type input[type="radio"].coupon-radio+label span,
.coupon-type input[type="radio"].coupon-radio+label.clr span {
    display: block;
    font-size: 22px;
    margin: 5px 0 0 0;
    font-weight: 700;
    color: #19d895;
}

.price-over-value {
    color: #ffffff;
    line-height: normal;
    font-size: 18px;
}

.price-over-value span {
    float: right;
    font-weight: 500;
}

.modal-search-plu {
    max-width: 100%;
    margin: 0;
}
.modal-search-plu .modal-content .modal-body {
    height: calc(100vh - 60px);
    overflow-y: auto;
}
.modal-search-plu .modal-content .modal-header {
    border-radius: 0;
}
.search-plu-next-prev button {
    cursor: pointer;
    height:100px;
    display: block;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    background: #9aa5b1;
    border-radius: 5px;
    width: 100%;
}

.checkout-table-summary {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #52606d;
    color: #ffffff;
}
.checkout-table-summary .summary-value {
    text-align: right;
}

.checkout-balance-bar {
    padding: 10px;
    background: #52606d;
}

.valuebox {
    text-align: center;
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    border-radius: 5px;
    background: #7b8794;
    color: #ffffff;
}
.valuebox span {
    font-size: 16px;
    font-weight: 400;
    color: #cbd2d9;
    margin-top: 5px;
    display: block;
}
.checkout-detail {
    padding: 15px;
}
.checkout-action-buttons {
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #1f2933;
}

.checkout-action-buttons .btn {
	text-transform: capitalize;
	padding: 10px 10px;
	line-height: normal;
	font-size: 14px;
	font-weight: 500;
    letter-spacing: 0.5px;
}

.checkout-action-buttons .btn>br {
    display: none;
}

.checkout-action-buttons .btn span {
    display: block;
    margin-bottom: 5px;
}

.checkout-action-buttons .btn span img {
    height: 26px;
}

.payment-type input[type=radio] {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.payment-type input[type=radio]+label,
.payment-type input[type=radio]+label.clr {
    cursor: pointer;
    border-radius: 5px;
    padding: 15px 15px;
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-weight: 400;
    background: #616e7c;
    color: #fff;
}

.payment-type input[type=radio]+label.chk {
    color: #fff;
    background: #19d895;
}

.payment-type input[type="radio"]+label span {
    margin-right: 5px;
    display: none;
}

.payment-type input[type="radio"]+label span img {
    height: 24px;
}

.calculator-keys-checkout {
    grid-template-columns: repeat(4, 1fr);
}

.calculator-keys-checkout button {
    margin: 0px 5px 10px 5px;
}

.keypadbox {
    padding: 0 5px;
}
.keypadbox h4 {
    font-size: 16px;
    color: #ffffff;
}
.selected-address {
    border-radius: 5px;
    padding: 5px;
    line-height: 20px;
    font-size: 20px;
    color: #ffffff;
}

.selected-address span {
    display: inline-block;
    vertical-align: top;
}

.selected-address span img {
    height: 20px;
    display: block;
}

.search-customer-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 330px;
    overflow-y: auto;
    background: #616e7c;
    border-radius: 5px;
}

.search-customer-list li {
    line-height: 26px;
    padding: 5px 15px;
    position: relative;
    color: #ffffff;
    font-weight: 500;
}

.search-customer-list li span {
    display: block;
    margin-top: 0;
    font-weight: 400;
    color: #cccccc;
}

.search-customer-list li i {
    font-size: 22px;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    width: 22px;
    height: 22px;
    margin: auto;
}

.search-customer-list li:nth-child(even) {
    background: rgba(0, 0, 0, 0.2);
}
.product-subcat {
    background: transparent;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    padding: 10px 5px;
    display: flex;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    height: 42px;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #8b8b8b;
}
.product-subcat.selected {
    background: transparent;
    color: #19d895;
    border-color: #19d895;
}
.subcat-panel {
    padding: 0px 10px;
    background: #3e4c59;
}
.notselect {
	padding: 50px 30px;
	height: 100%;
	text-align: center;
}
.notselect h4 {
	font-size: 18px;
	color: #ffffff;
}
.notselect span {
    display: block;
    margin: 0 auto 1rem auto;
}
.notselect span img {
    height:100px;
    display: block;
    margin: auto;
}
.custom-product-controls button {
    cursor: pointer;
    background: #9aa5b1;
    display: block;
    height: 100px;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
}
.dashboard-right-actions,
.button-new-order {
    text-align: right;
}

.dashboard-right-actions .custom-grid-column,
.button-new-order .custom-grid-column {
    margin-right: 0;
}

.Mb-modal-header,
.Mb-modal-title {
    display: none;
}

.textboxselected {
    border: 1px solid #19d895;
}

.setting-tabs {
    position: relative;
    /* padding-left: 150px; */
}

.setting-tabs .nav-tabs {
    display: block;
    position: absolute;
    left: 0;
    padding: 0;
    margin: 0;
    top: 0;
}

.setting-tabs .nav-tabs .nav-item {
    margin-bottom: 1rem;
    margin-right: 0;
}
.setting-tabs .nav-tabs .nav-link {
	display: block;
	text-align: center;
	padding: 15px;
	width: 100%;
	font-weight: 500;
}
.setting-tabs .nav-tabs .nav-link span {
	display: block;
	width: 35px;
	margin: auto auto 5px auto;
}

.setting-box h4 {
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1rem;
}

.tendertype-selection ion-item {
    margin-bottom: 1rem;
    border: 1px solid #52606d;
    --ion-color-base: #52606d !important;
    border-radius: 5px;
}

.tendertype-selection ion-item ion-radio {
    margin-inline-end: 20px;
}

.tendertype-notfound {
    background: #52606d;
    padding: 50px 20px;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.tendertype-notfound h4 {
    font-size: 16px;
    font-weight: 400;
    color: #ff9f99;
}

.tendertype-notfound span {
    display: block;
    font-size: 22px;
    margin-bottom: 10px;
}
.printer-box {
    background: #3e4c59;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.printer-box h5 {
    color: #ffffff;
    font-size: 18px;
    line-height: normal;
    margin: 0 0 20px 0;
    font-weight: 400;
    text-align: center;
}
.setting-box {
    background: #3e4c59;
    padding: 20px;
    border-radius: 5px;
}
.express-mode {
    background: #52606d;
    border-radius: 5px;
    line-height: 50px;
    display: inline-block;
    padding: 0 40px;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}

.express-mode:hover,
.express-mode:focus {
    color: #ffffff;
    background: #3e4c59;
}

.desktop-hide {
    display:none;
}

.btn-order-selection {
    color: #ffffff;
    text-transform: uppercase;
    background: #616161;
    font-size: 15px;
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
    font-weight: 500;
    padding: 20px 0px;
    height: auto;
}

.modal-addons-customize .modal-content {
    height: 100%;
    display: block;
    border-radius: 0;
}
.modal-addons-customize .modal-content .modal-body {
	height: calc(100vh - 60px);
	overflow-y: auto;
    padding: 20px;
}
.modal-addons-customize .modal-content .modal-header {
    border-radius: 0;
}
.addon-toolbar {
    margin-bottom: 10px;
}
.addon-tabs-head {
    margin-bottom: 10px;
}
.addon-tabs-content {
    background: #616e7c;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #7b8794;
}
.addon-select-box label {
    display: grid;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 10px;
    height: 90px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    background: #3e4c59;
    align-items: center;
    color: #cbd2d9;
}

.addon-select-box .addon-item-price {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
}

.addon-select-box input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.addon-select-box label.selected,
.addon-select-box label:focus,
.addon-select-box label:focus-visible {
    background: #19d895;
}
.addon-select-box label.selected,
.addon-select-box label:focus,
.addon-select-box label:focus-visible,
.addon-select-box label.selected .addon-item-price,
.addon-select-box label:focus .addon-item-price,
.addon-select-box label:focus-visible .addon-item-price {
    color: #ffffff;
}

.crus-type input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}
.crus-type label {
    display: block;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    background: transparent;
    margin-bottom: 0;
    border: 1px solid #7b8794;
    position: relative;
    /* border-bottom: 0; */
}
.crus-type h5 {
    font-size: 15px;
    font-weight:400;
    line-height: 18px;
    margin: 0;
}

.crus-type .item-size-price {
    font-size: 15px;
    font-weight:600;
    color: #cbd2d9;
    margin-top: 5px;
}

.crus-type input[type="radio"]:checked+label {
    border-color: #19d895;
    background: #19d895;
}

.crus-type input[type="radio"]:checked+label h5,
.crus-type input[type="radio"]:checked+label .item-size-price {
    color: #ffffff;
}

.sub-crus-type input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.sub-crus-type h5 {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
    line-height: 20px;
    min-height: 30px;
    margin: 0;
}

.sub-crus-type .item-size-price {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
}

/* .crus-type input[type="radio"]:checked+label {
    border-color: #7b8794;
    background: #616e7c;
} */

.addon-heading {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
}

.group-condition-fail {
    color: #ff6258 !important;
}

.group-condition-success {
    color: #19d895;
}
.limerr-icon {
    width: 80px;
    height: 80px;
    margin: auto auto 1rem auto;
    background: #616161;
    border-radius: 50%;
    padding: 5px;
}
.limerr-icon img {
    display: block;
    margin: auto;
}
.tabmenu {
    display: none;
}
.grid-lblname h6 {
    display: none;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.grid-lblname {
    margin-bottom:0px;
}
.grid-lblname img {
	margin-right: 5px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	top: 2px;
}
.norecord {
    position: static;
    background: #3e4c59;
    border-radius: 5px;
    padding: 10% 0;
    height: auto;
}
.content-box .order-box {
    /* background:#616161;
    border-radius: 5px;
    margin-bottom:10px; */
}
.content-eod .ngx-pagination {
    padding: 0;
}
.page-end-of-day .page-heading {
    margin-bottom: 1rem;
}
.content-box .notfound {
    position: static;
    padding: 8% 0;
    height: auto;
    background: #3e4c59;
    border-radius: 5px;
}
.product-categories {
    padding: 10px 10px 100px 10px;
    background: #52606d;
    position: fixed;
    right: 0;
    width: 180px;
    height: 100vh;
    top: 0;
    overflow-y: auto;
}
.product-sub-categories .nav-scroller-item.selected {
    border-color: #19d895;
    background: #19d895;
    color: #ffffff;
}
.btn-add, .btn-back {
    padding: 8px 20px;
}
.table-layout-box {
    padding-top: 80px;
}
.table-items-sorting {
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #52606d;
    z-index: 9;
}
.table-items-sorting ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-items-sorting ul li {
    display: inline-block;
    margin-right: 10px;
}
.table-items-sorting ul li:last-child {
	margin-right: 0px;
}
.table-items-sorting .nav-tabs .btn {
    font-size: 14px;
    text-align: center;
    padding: 6px 0px;
    line-height: normal;
    font-weight: 500;
    width: 100%;
    display: block;
}
.table-items-sorting .nav-tabs .btn span {
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.table-items-sorting .nav-tabs .btn-home {
    padding: 9px 0px;
}
.table-items-sorting .nav-tabs .btn-text {
    padding: 9px 0px;
    text-transform: uppercase;
    line-height: 20px;
}
.table-items-sorting .drp-booking-time {
    background: #616e7c;
    border-radius: 5px;
    width: 100%;
    display: block;
    border: 0;
    color: #ffffff;
    padding: 20px 10px;
    line-height: 1;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}

.table-display-top .nav-tabs {
    border-bottom: 0px solid #dddddd;
    padding: 10px;
    margin: 0;
    background: #3e4c59;
}
.table-display-top .nav-tabs .nav-item {
    padding: 0px;
    position: relative;
    margin-right: 10px;
}
.table-display-top .nav-tabs .nav-item:last-child {
    margin-right: 0px;
}
.table-display-top .nav-tabs .nav-link {
	color: #ffffff;
	font-size: 14px;
	border: 0;
	background: #52606d;
	border-radius:5px;
	line-height: 20px;
	padding: 10px 50px 10px 15px;
	font-weight:400;
}
.table-display-top .nav-tabs .nav-link span {
	background: rgba(0,0,0,0.4);
	color: #ffffff;
	border-radius: 50px;
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
	text-align: center;
	position: absolute;
	right: 10px;
	top: 0;
	font-weight: 600;
	bottom: 0;
	margin: auto;
}
.table-display-top .nav-tabs .nav-item.show .nav-link,
.table-display-top .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #28c76f;
    border: 0;
    box-shadow: none;
}
.table-display-top .nav-tabs .btn-sync {
	width: 40px;
	height: 40px;
	border-radius:5px;
}
.table-display-top .nav-tabs .btn-sync > img {
	height: 25px;
}
.table-display-layout {
	padding: 10px;
	height: calc(100vh - 140px);
	overflow-y: auto;
}
.table-box {
	border-radius:5px;
	margin-bottom: 10px;
	overflow: hidden;
	background-color: #3e4c59;
}
.table-box.occupied {
    background-color: #2196f3;
}
.table-box.reserved {
    background-color: #8862e0;
}
.table-box.available {
    background-color: #19d895;
}
.table-box-content {
	padding:15px;
	position: relative;
	min-height: 120px;
}
.table-box .table-booking-time {
    position: absolute;
    color: #ffffff;
    right: 15px;
    top: 15px;
}
.table-box .table-no {
    color: #ffffff;
    font-weight: 700;
    font-size:22px;
    margin-bottom: 5px;
    line-height: normal;
}
.table-customername {
    color: rgba(255, 255, 255, 0.8);
}
.table-box .table-price {
    margin-bottom: 10px;
    color: #ffffff;
}
.table-merge {
    line-height: normal;
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #ffffff;
}
.table-merge img {
	height: 18px;
	margin-right: 5px;
}
.table-box .tickmark {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
}
.table-box .tickmark img {
    height: 20px;
    width: auto;
}
.table-box.selected-table .tickmark {
    display: block;
}
.table-box.selected-table-atchange .tickmark {
    display: block;
}
.table-box.selected-table-atmerge .tickmark {
    display: block;
}
.table-box.selected-table-atmerge {
    background-color: #ffffff;
    color: #000000;
}
.orderdetail-header {
	background: #616e7c;
	position: relative;
	height: 50px;
}
.orderdetail-header h2 {
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

/* Chrome, Safari, Edge, Opera -- Hide Arrows From Input Number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #52606d inset !important;
    -webkit-text-fill-color: #ffffff !important;
}
.page-title {
    font-weight: 500;
    margin: 0;
    line-height: 42px;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: #ffffff;
}
.page-title span {
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
}
.pagination-next-prev {
    position: static;
    bottom: 0;
    right: 0;
    width: 100%;
}
.next-prev-bar{
	position: static;
	bottom: 47px;
	width: 100%;
	left: 0;
	padding: 0 20px;
}
.cart-order-list {
    padding:0px;
  }
  .cart-header {
    background: #52606d;
    color: #ffffff;
    padding: 10px 5px;
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
}
  .cart-body {
    height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cart-item-row {
    color: #ffffff;
    padding:8px 5px;
    position: relative;
    background: #52606d;
    margin-top: 1px;
    clear: both;
    font-size: 14px;
  }
  .cart-item-row.show {
    background: #1f2933;
  }
  .cart-item-row .dropdown-menu {
    background: transparent;
      padding: 10px 10px 0px 10px;
      border-radius: 0;
      width: 100% !important;
      border: 0;
      left:5px !important;
      right:-5px !important;
      border-radius: 5px;
      margin:15px 0px 15px 5px; 
  
      position: static !important;
      transform: none !important;
      margin: 0;
      float: none;
  
  }
  .cart-item-row .dropdown-menu .button-action {
    text-align: center;
      padding: 5px 5px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
  }
  .cart-item-row .dropdown-menu .button-action span {
    display: block;
      margin: auto auto 5px auto;
  }
  .cart-item-row .dropdown-menu .button-action span img {
    height: 18px;
      width: auto;
      display: inline-block;
      margin: auto;
  }
  
  .cart-item-row .dropdown-toggle::after {
    display: none;
  }
  .column-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column-qty {
    width: 40px;
    text-align: center;
    padding: 0 5px;
    font-weight: 600;
  }
  .column-item {
    width: 80%;
}
  .column-price {
    width: 70px;
    margin-left: auto;
    margin-right: 10px;
    text-align: right;
  }
  .column-action {
    width:90px;
    padding: 0 5px;
    text-align: right;
  }
  .btn-dots {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
  }
  .transaction-lookup-top {
	padding: 10px;
	background: #52606d;
	display: flex;
}
.filter-by-search {
    width: 40%;
    position: relative;
    margin: 0px;
}
.filter-by-search .btn-search {
	color: #ffffff;
	border: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding: 0;
	background: transparent;
}
.filter-by-search .form-control {
    padding: 10px 60px 10px 20px;
    background: #7b8794;
    border-radius: 5px;
}
.search-customer-listing {
	position: absolute;
	top: 50px;
	border: 0;
	width: 100%;
	left: 0;
	right: 0;
	margin: auto;
	overflow: hidden;
	z-index: 1;
}
.transaction-lookup-top .btn-home {
    border-radius: 5px;
    width: 50px;
    padding: 0;
    margin-left: auto;
}
.transaction-lookup-top .btn-filter {
	border-radius: 5px;
	width: 50px;
}
.order-list-statistics .pagination-next-prev {
	bottom: 30px;
    left: 0;
}
.modal-filter-detail {
    background: #3e4c59;
    position: fixed;
    margin: auto;
    top: 0px;
    height: calc(100% - 0px);
    -webkit-transform: translate3d(0%, 0, 0) !important;
    -ms-transform: translate3d(0%, 0, 0) !important;
    -o-transform: translate3d(0%, 0, 0) !important;
    transform: translate3d(0%, 0, 0) !important;
    left: -400px;
    max-width: 400px;
    width: 100%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.fade .modal-filter-detail {
    -webkit-transition: .3s ease-out !important;
    -o-transition: .3s ease-out !important;
    transition: .3s ease-out !important;
}

.modal.show .modal-filter-detail {
    left: 0;
}
.modal-filter-detail .modal-body {
	padding: 0;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
}
.modal-filter-detail button.close {
    color: #ffffff;
    position: absolute;
    right: -60px;
    background: #7b8794;
    width: 60px;
    height: 60px;
    border-radius: 0;
}
.widget {
    padding: 15px 15px 0px 15px;
}
.widget .widget-title {
    margin-bottom: 10px;
    color: #ffffff;
    font-weight: 500;
}
.modal-order-detail button.close {
    color: #ffffff;
    position: absolute;
    left: -60px;
    background: #616e7c;
    width: 60px;
    height: 59px;
    border-radius: 0;
}
.btn-neworder {
    border-radius: 5px;
    padding: 0 20px;
}
.product-listing {
    padding: 10px;
    overflow-y: auto;
    height: calc(100vh - 160px);
}
.modal-addons-customize {
    max-width: 100%;
    margin: 0;
}
.netinfo {
    background: #f0595f;
    position: absolute;
    right: 80px;
    width: 40px;
    border-radius: 0 0 20px 20px;
    padding: 8px 14px;
}
.modal-netinfo h2 {
    color: #ffffff;
    text-align: center;
}
.modal-netinfo h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 0;
}
.modal-netinfo .modal-body {
    padding: 40px;
}
.feature-box {
    border-radius: 10px;
    padding: 15px 5px;
    text-align: center;
    background: #52606d;
    margin-bottom: 2rem;
}
.feature-box h3 {
    color: #ffffff;
    font-size: 15px;
    font-weight:400;
    margin-top: 10px;
}
.feature-box span {
    margin: auto auto 10px auto;
}
.feature-box span img {
    height: 50px;
    display: block;
    margin: auto;
}
.notaccess-features {
    padding: 30px 20px;
}
.notaccess-features h3 {
    margin-bottom: 2rem;
    line-height: 30px;
    font-size: 20px;
    font-weight: 400;
}
.notitemfound {
    text-align: center;
    margin-top: 10%;
    color: #ffffff;
    font-size: 18px;
}

.notitemfound span {
    display: block;
    margin: auto auto 20px auto;
    width: 100px;
}
 

app-sidebar {
    display: none;
}

.navbar .container-fluid {
    padding: 0;
}

#sidebar.show {
    margin-left: 0px;
}

.username {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #555;
}

.username span {
    color: #28b351;
}

.btn-humberg {
    background: transparent;
    border: 0;
    font-size: 26px;
    line-height: 50px;
    padding: 0;
    cursor: pointer;
    width: 60px;
    border-right: 1px solid #dddddd;
}

.breadcrumb-section {
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

a.btn-backward {
    color: #ffffff;
    height: 100%;
    width: 100px;
    text-align: center;
    display: block;
    margin-left: auto;
    line-height: 54px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: 500;
}

.breadcrumb {
    padding: 0;
    margin: 0;
}

.breadcrumb li {
    float: left;
}

.breadcrumb li a {
    color: #ffffff;
    text-decoration: none;
    padding: 16px 16px 16px 36px;
    background: #283046;
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 0px 0px 0px 0px;
    text-align: center;
    font-weight: 500;
    margin-right: 1px;
}

.breadcrumb li a::before {
    position: absolute;
    top: 0;
    content: '';
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 0 27px 18px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.2);
    z-index: 1;
}

.breadcrumb li:first-child a {
    padding-left: 20px;
}

.breadcrumb li:first-child a:before {
    border: none;
}

.breadcrumb li a:after {
    position: absolute;
    top: 0;
    content: '';
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 0 27px 18px;
    border-color: transparent transparent transparent #283046;
    z-index: 2;
}

.breadcrumb li:last-child a {
    background: #19d895 !important;
    pointer-events: none;
    cursor: default;
    color: #ffffff;
}

.breadcrumb li:last-child a:after {
    border-color: transparent transparent transparent #19d895;
}






.drp-active-inactive {
    width: auto;
    padding: .6rem 1rem;
    display: inline-block;
    margin-left: 1rem;
}




.completedorders .order-box {
    position: relative;
}

.completedorders .order-box:before {
    content: "";
    height: 50px;
    width: 4px;
    border-radius: 5px;
    background: #708aff;
    position: absolute;
    content: "";
    left: -2px;
    top: 0;
    bottom: 0;
    margin: auto;
}



label.discount-label {
    background-image: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.modal-discount h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 10px;
}

label.coupon-label {
    background-image: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.coupon-triangle {
    position: absolute;
    color: #222222;
    font-weight: 600;
    background: palegreen;
    border-radius: 5px;
    padding: 4px 0px;
    font-size: 12px;
    line-height: normal;
    top: -11px;
    width: 30%;
    left: 35%;
    text-align: center;
}


.modal-coupon h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 10px;
}

.toggle.btn-xs {
    min-width: 60px !important;
    min-height: 23px !important;
    padding: 0px !important;
}

.hideshow-checkbox {
    display: inline;
    vertical-align: top;
    margin-left: 10px;
    top: -5px;
}

.box {
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
}

.box-title {

    font-weight: 600;
    background: #f0f3f7;
    padding: 10px 15px;
}

.box-body {
    padding: 15px 15px 0px 15px;
    border-top: 1px solid #bbbbbb;
}

.menu-categories {
    background: #ffffff;
    display: block;
    height: calc(100vh - 98px);
    position: relative;
    z-index: 9;
}

.menu-categories a {
    color: #222;
    font-size: 14px;
    padding: 0px 15px 0px 20px;
    border-bottom: 1px solid #eee;
    font-weight: 500;
    display: block;
    line-height: 40px;
    overflow: hidden;
}

.menu-categories a span {
    float: right;
    position: relative;
    color: #666666;
    top: 3px;
}

.menu-categories a:hover,
.menu-categories a:focus,
.menu-categories a.active {
    color: #708aff;
    box-shadow: inset 4px 0px #708aff;
}


.system-date-time {
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #708aff;
}

.heading {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 30px;
}

.border-box {
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.dispatch-tabs .tab-content {
    padding: 20px;
}

.dispatch-tabs .nav-tabs {
    background: #ffffff;
}

.dispatch-tabs .nav-tabs .nav-link {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #ffffff;
    text-transform: uppercase;
    color: #222;
    border-bottom: 3px solid transparent;
}

.dispatch-tabs .nav-tabs .nav-item.show .nav-link,
.dispatch-tabs .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-bottom-color: #708aff;
}

.tax-type {
    display: inline-block;
    margin-right: 2rem;
    margin-top: 10px;
}

.inventory-header {
    text-align: center;
}


.tender-status-active {
    position: absolute;
    right: 0;
    top: 1rem;
    background: #708aff;
    color: #ffffff;
    border-radius: 50px 0px 0px 50px;
    line-height: 1;
    padding: 6px 10px 7px 15px;
    font-size: 14px;
}

.tender-status-inactive {
    position: absolute;
    right: 0;
    top: 1.25rem;
    background: #f7778c;
    color: #ffffff;
    border-radius: 50px 0px 0px 50px;
    line-height: 1;
    padding: 6px 10px 7px 15px;
    font-size: 14px;
}

.tender-info {
    font-size: 18px;
    line-height: normal;
    color: #222;
    font-weight: 500;
}

.tender-info span {
    text-transform: uppercase;
    display: block;
    font-size: 12px;
    color: #A5A5A5;
    margin-bottom: 5px;
}

.selected-order-list {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.seleced-item-head {
    padding: 10px;
    border-bottom: 1px solid #dddddd;
    font-weight: 700;

}

.seleced-item-row {
    padding: 10px;
}

.seleced-item-row:nth-child(even) {
    background: #eeeeee;
}

.seleced-item-bottom {
    padding: 10px;
    background: #eeeeee;
    font-weight: 700;
    font-size: 18px;
    position: absolute;
    border-top: 2px solid #cccccc;
    bottom: 0;
    width: 100%;
    left: 0;
}

.checkout-order-amount {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
}



.select-all {
    display: inline-block;
}

.select-all label {
    font-size: 18px;
}

.category-box {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 2rem;
    position: relative;
    background: #ffffff;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}

ul.category-info {
    padding: 0px;
    margin: 0;
}

ul.category-info li {
    display: inline-block;
    margin-right: 1rem;
}

ul.category-info li span {
    font-weight: 600;
    color: #222222;
}

.category-box h4 {
    font-size: 20px;
    font-weight: 700;
    color: #222222;
    margin-top: 5px;
}

.category-box .btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 50px;
    line-height: 1;
    padding: 8px 15px;
}

.filters-box .form-control {
    padding: .5rem 1rem;
    line-height: normal;
}

.menu-items-page {
    padding: 0;
    height: calc(100vh - 81px);
}

.menu-listbox {
    border-bottom: 1px solid #dddddd;
    padding: 10px;
}

.menu-listbox p {
    margin: 0;
}

.menu-listbox h4 {
    font-size: 18px;
    font-weight: 700;
}

.icon-tender-type img {
    height: 60px;
    display: block;
}

.custom-checkbox {
    margin-bottom: 1rem;
}

.menu-list-datagrid {
    padding: 20px;
}

/* invoice css */

.invoice-pos-one-min {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    width: 100%;
    height: auto;
    display: block;
    font-size: 14px;
    line-height: 24px;
    padding: 0mm 2mm;
    margin: 0;
    color: #000000;
}

.invoice-pos-one-min h2 {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
}

.invoice-pos-one-min h4 {
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    margin: 10px 0;
}

.invoice-pos-preview {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    height: auto;
    display: block;
    font-size: 14px;
    line-height: 24px;
    padding: 0mm 2mm;
    margin: 0;
    color: #000000;
}

.invoice-pos-preview h2 {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
}

.invoice-pos-preview h4 {
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    margin: 10px 0;
}

.invoice-pos {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    width: 72mm;
    height: auto;
    display: none;
    font-size: 14px;
    line-height: 18px;
    padding: 1mm;
    margin: 0;
}

.invoice-pos h2 {

    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
}

.invoice-pos h4 {
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    margin: 10px 0;
}

.invoice-pos-eod {
    font-family: Arial, Helvetica, sans-serif;
    width: 72mm;
    height: auto;
    display: none;
    font-size: 12px;
    line-height: 18px;
    padding: 2mm;
    margin: auto;
}

.invoice-pos-eod h2 {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
    font-weight: bold;
}

.invoice-pos-eod h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    margin: 10px 0;
}

.invoice-pos-eod .table-detail tr td {
    line-height: 24px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

.table-detail tr td {
    vertical-align: middle;
    line-height: 24px;
}

.company-info h4 {
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 700;
}

.table-items-list {
    margin-bottom: 10px;
}

.table-items-list tr th,
.table-items-list tr td {
    padding: 5px;
    vertical-align: middle;
    line-height: normal;
}

.table-items-list tr th {
    border-bottom: 2px solid #dddddd;
    padding: 5px;
    border-top: 1px solid #dddddd;
    font-weight: bold;
}

.table-transaction-info {
    margin-bottom: 10px;
}

.line {
    border-top: 1px dashed #cccccc;
    margin: 10px 0;
    width: 100%;
    clear: both;
}

.alert-message {
    text-align: center;
    font-size: 18px;
    border: 1px solid #d9d9d9;
    margin-bottom: 1rem;
    padding: 1rem;
}

.order-total-row {
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    bottom: 88px;
    width: 100%;
    left: 0;
    color: #ffffff;
    border-top: 2px solid #4b5776;
}

.order-total-row span {
    font-size: 22px;
    font-weight: 600;
    display: block;
}

/* .selected {
	background-color: rgb(40, 95, 239);
	color: #ffffff !important;
} */

@media print {
    .noprint {
        visibility: hidden;
    }
}

/* change by Fleming */
.block-purple span {
    font-weight: 400;
    font-size: 2.5rem;
    display: block;
    text-transform: capitalize;
    line-height: 1;
    margin: 0 auto 10px auto;
}




.table-items-list tr.selected {
    box-shadow: inset 5px 0px #708aff;
    color: #222 !important;
    background: #f8f8f8;
}



.order-box.selected h4 {
    font-weight: 700;

}

.block {
    background: #f8f8f8;
    border-radius: 5px;
    display: block;
    padding: 30px;
    text-align: center;
    color: #222222;
    min-height: 120px;
    display: grid;
    vertical-align: middle;
    align-content: center;
    margin-bottom: 10px;
    border: 1px solid #dddddd;

    font-weight: 500;
}

.block span {
    font-size: 32px;
    color: #C5C5C5;
}

.under-development {
    text-align: center;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.under-development h3 {
    margin-bottom: 30px;
}

.btn-custom {
    font-size: 14px;
    font-weight: 700;
}



.datetime {
    margin-bottom: 15px;

}

.datetime span {
    font-size: 30px;
    font-weight: 700;
    vertical-align: bottom;
    display: inline-block;
}

.customer-order-info span {
    margin-right: 30px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    line-height: normal;
}

.customer-order-info span font {
    display: block;
    font-size: 20px;
    font-weight: 700;
}

.customer-order-info {
    padding: 10px;
}

.customer-basic-information {
    line-height: 22px;
    font-size: 16px;
    padding: 6px 15px;
    font-weight: 500;
    position: relative;
    color: #ffffff;
    background: #52606d;
    margin-bottom: 10px;
    border-radius: 5px;
}

.customer-basic-information.selectedCustomer {
    background: #19d895;
    color: #ffffff;
}
.customer-basic-information.selectedCustomer span {
    color: #ffffff;
}

.customer-basic-information span {
    display: block;
    color: #cbd2d9;
    font-weight: 400;
    font-size: 14px;
}

.customer-basic-information .arrow-more {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    margin: auto;
    text-align: center;
}
.customer-basic-information .arrow-more img {
    display: block;
    margin: auto;
}
.customer-detail-top {
    padding: 15px 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.btn-edit {
    padding: 8px 20px;
    font-size: 14px;
}

.cname {
    font-weight: 500;
    font-size: 18px;
}

.customer-detail-bottom {
    padding: 15px;
}

.customer-detail-bottom .customer-lbl {
    font-size: 13px;
    color: #555555;
    line-height: normal;
    margin-bottom: 8px;
}

.customer-detail-bottom .customer-value {
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
}

.confirm-msg {
    border-radius: 3px;
}

.confirm-msg span {
    font-size: 18px;
    margin-right: 10px;
}

.confirm-msg .btn {
    padding-right: 30px;
    padding-left: 30px;
}

.selected-customer {
    font-weight: 500;
    text-align: left;
}

.selected-customer span {
    margin-left: 1rem;
}





.order-box-wrapper {
    padding: 10px;
}

.table-transaction-info tr td,
.gst-percentage tr td {
    line-height: 24px;
}

.items-amount-detail tr td {
    line-height: 24px;
}


.product-listing .ngx-pagination {
    padding: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.invoice-footer {
    margin-top: 10px;
}

.company-logo {
    padding-bottom: 10px;
}

.company-logo img {
    height: 150px;
    width: auto;
    display: block;
    margin: 0 auto;
}

.allcategories {
    position: absolute;
    z-index: 9;
    background: #0b0c10;
    width: 100%;
    left: 0px;
    padding: 0px 10px 0px 10px;
    top: 80px;
}

.btn-more {
    min-height: 60px;
    background: #575965;
    border-color: #575965;
}

div#one-min-cash {
    max-height: 500px;
    overflow-y: auto;
    width: 120mm;
    background: #ffffff;
}

div#one-min-cash-display {
    max-height: 500px;
    overflow-y: auto;
    width: 120mm;
    background: #ffffff;
}
.thankyou-msg {
    text-align: center;
    height: 240px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.thankyou-msg .icons {
    margin: auto auto 2rem auto;
    display: block;
}
.thankyou-msg .icons img {
    height: 100px;
    display: block;
    margin: auto;
}
.thankyou-msg h3 {
    margin-bottom: 2rem;
    font-size: 22px;
    color: #ffffff;
}


div#end-of-day {
    max-height: 500px;
    overflow-y: auto;
    background: #ffffff;
}



.order-list-statistics .tab-content .tab-pane {
    height: calc(100vh - 90px);
    position: relative;
    padding: 0;
}


.filter-options .calculator-keys {
    margin: 0 74px 0 auto;
}





.btn-clear {
    color: #000000;
    background: #ffffff;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    border-radius: 3px;
    line-height: 48px;
}


.btn-refresh {
    width: 60px;
}

.summary-box {
    height: 100vh;
    position: relative;
    z-index: 1;
}

.input-date-selection {
    background: #ffffff;
    ;
}


.single-orderdetails .action-buttons .btn {
    padding: 10px 15px;
    margin-left: 10px;
}

.plu-info {
    font-family: 'Roboto', sans-serif;
    width: 88mm;
    height: auto;
    display: none;
    font-size: 20px;
    line-height: 25px;
    padding: 2mm;
    margin: 0 auto;
}

.order-box:after {
    display: none;
    content: ">";
    position: absolute;
    right: 15px;
    color: #ffffff;
    top: 0;
    font-size: 18px;
    z-index: 9;
    width: 35px;
    background: #55607f;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    margin: auto;
    bottom: 0;
}








.driver-list-body {
    padding: 10px 0px;
}

.driver-repeated {
    padding: 10px 20px;

}
.size-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.size-toolbar label {
    display: block;
    background-color: #4b5776;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px dashed #888888;
}

.size-toolbar input[type="radio"]:checked+label {
    background-color: #8fa3fc;
    border-color: #ffffff;
}

.size-toolbar label h4 {
    color: #ffffff;
    font-size: 18px;
    margin: 0 0 4px 0;
    line-height: normal;
}

.size-toolbar label .item-size-price {
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
}

.item-size-icon {
    float: left;
    margin-right: 20px;
}


.addon-toolbar label h4 {
    color: #ffffff;
    font-size: 13px;
    margin: 0 0 10px 0;
    line-height: 18px;
    min-height: 40px;
    font-weight: 400;
}



/*Manager Module Css*/
.menu-box {
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    letter-spacing: 1px;

    font-weight: 400;
    margin-bottom: 10px;
    align-items: center;
    justify-items: center;
    display: grid;
    min-height: 130px;
}

.menu-box span {
    font-size: 2.5rem;
    line-height: 1;
}

.menu-box:hover,
.menu-box:focus {
    color: #ffffff;
}

.block-light,
.block-light:hover,
.block-light:focus {
    background: #283046;
    color: #ffffff;
}

.btn-datatable-action {
    border-radius: 3px;
    padding: 8px 10px;
}


.main-content .card .card-body {
    height: 72vh;
    overflow-y: auto;
}

.form-wrapper-box {
    background: #ffffff;
    border-radius: 3px;
    padding: 30px;
    margin-bottom: 30px;
}



.form-wrapper-box .label-horizontal {
    line-height: normal;
    color: #555;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 5px;
}

.form-wrapper-box .form-group {
    margin-bottom: 20px;
    position: relative;
}

.form-wrapper-box .form-group1 {
    margin-bottom: 0px;
}

.form-wrapper-box .form-group .help-block {
    position: absolute;
    left: 0;
    bottom: -20px;
}

.selection-element input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.selection-element label {
    display: block;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid #cccccc;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 15px;
}

.selection-element input[type="checkbox"]:checked+label {
    background-color: #708aff;
    border-color: #708aff;
    color: #ffffff;
}





.product-sub-categories .nav-scroller-item:hover,
.product-sub-categories .nav-scroller-item:focus {
    color: #ffffff;
    text-decoration: none;
}

.blink_text {
    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    font-weight: 700;
    margin-left: auto;
    display: inline-block;
    margin-right: 30px;
}

@keyframes blinker {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}




.page-reports,
.page-admin,
.page-manager-desk {
    padding-top: 56px;
}


.page-settings {
    padding-top:50px;
}

.table-action-buttons button {
    width: 34px;
    height: 34px;
    font-size: 18px;
    line-height: normal;
    text-align: center;
    margin: 0 5px;
}
.address-type {
    --background: transparent;
    --color: #ffffff;
    font-size: 14px;
}
.customer-infobar {
    background: #3e4c59;
    padding: 15px;
}
.customer-infobar span {
    color: #ffffff;
}
.customer-details-section {
    position: relative;
    padding: 15px;
}
.customer-details-section .nav-tabs {
    padding: 0;
}
.customer-details-section .nav-tabs .nav-item {
    margin: 0 10px 0 0;
}
.customer-details-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border-radius: 5px;
    background: #52606d;
    font-size: 16px;
    font-weight: 400;
}
.customer-details-section .nav-tabs .nav-link.active {
    background:#19d895;
}
.customer-details-section .nav-tabs .nav-link span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.customer-details-section .nav-tabs .nav-link span img {
    height: 30px;
    display: block;
    margin: auto;
}

.order-box-arrow {
    position: absolute;
    right: 10px;
    top: 0;
    color: #989eaf;
    font-size: 28px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    margin: auto;
}
.customer-address-box {
    background: #3e4c59;
    padding: 20px;
    margin-bottom:10px;
    position: relative;
    border-radius: 5px;
}
.customer-address-box h4 {
    color: #ffffff;
    margin-top: 0;
    font-size: 16px;
}
.customer-address-box .address,
.customer-address-box .phone {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    font-size: 15px;
}
.customer-address-box .address span,
.customer-address-box .phone span {
    position: absolute;
    left: 0;
    top: 3px;
}
.customer-address-box .address span img,
.customer-address-box .phone span img {
    height: 18px;
    width: auto;
    display: block;
}

.customer-address-box .created {
    line-height: 19px;
}
.address-action-buttons button {
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 22px;
    border-radius: 5px;
    margin-left: 15px;
    line-height: 44px;
    display: inline-block;
    vertical-align: top;
}
.statistics-box {
    background: #343d54;
    margin-bottom: 2rem;
    padding: 15px;
    position: relative;
    color: #ffffff;
    border-radius: 5px;
}

.statistics-box h4 {
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.statistics-box .statistics-count {
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    color: #ffffff;
}

.statistics-icon {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.4;
}

.customer-dashboard-section {
    padding: 30px;
}





.filter-sidebar {
    min-width: 350px;
    max-width: 350px;
    background: #3e4968;
    color: #ffffff;
    transition: all 0.3s;
    margin-left: -350px;
    padding: 0px;
    position: relative;
    z-index: 9;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.filter-sidebar.active {
    margin: 0;
}
.filter-selection .btn {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 50px;
    line-height: normal;
    padding: 6px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #616e7c;
    border: 1px solid #616e7c;
}

.filter-selection .btn.active {
    background: #19d895;
    color: #ffffff;
}

.filter-bottom-bar {
	padding: 15px;
	position: static;
	bottom: 0px;
	left: 0;
	width: 100%;
	z-index: 1;
}




.main_container .top_nav {
    display: none;
}


.welcome-msg {
    margin: 0 0 2rem 0;
}

.welcome-msg h2 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.linkbox {
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid transparent;
    margin: 0;
    color: #ffffff;
    background: #283046;
    cursor: pointer;
    width: 135px;
    height: 130px;
    margin-bottom: 20px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    font-size: 16px;
}

button.linkbox[disabled] {
    cursor: default;
    opacity: 0.2;
}

.linkbox span {
    font-size: 28px;
    display: block;
    text-transform: capitalize;
    margin: 0px auto 10px auto;
}

.linkbox:hover,
.linkbox:focus {
    color: #ffffff;
    background: #283046;
}




.filter-options .form-control {
    background: #161d31;
    padding: 11px 15px;
}






.filter-count {
    position: absolute;
    font-size: 12px;
    background-color: #19d895;
    width: 25px;
    height: 25px;
    color: #fff;
    right: 5px;
    text-align: center;
    top: 5px;
    border-radius: 50%;
    line-height: 25px;
    font-weight: 500;
}



.top-head-section {
    color: #ffffff;
    background: #616e7c;
    margin: 0;
    padding: 5px 10px;
}

.top-head-section .btn-home {
    width: auto;
    font-size: 14px;
    line-height: normal;
    height: 50px;
    padding:0px 10px;
    border-radius: 5px;
}

.top-head-section h2 {
    margin: 0;
    font-size: 18px;
}

.customer-searchbox .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9aa5b1;
}

.customer-searchbox .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #9aa5b1;
}

.customer-searchbox .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #9aa5b1;
}

.customer-searchbox .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #9aa5b1;
}

.order-select-checkbox {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    z-index: 1;
}

.order-select-checkbox input[type="checkbox"] {
    vertical-align: top;
}

.pay-later-total-amount {
    color: #ffffff;
    font-size: 16px;
}

.pay-later-total-amount span {
    display: block;
    font-size: 20px;
    font-weight: 700;
}
.content-eod .pagination-next-prev {
    position: static;
}
.page-end-of-day .content-box .next-prev-bar {
    padding: 0 20px;
    margin: 10px 0 30px 0;
}
.transaction-lookup-grid {
    padding: 5px;
}



.attendance-tabs table td:first-child,
.attendance-tabs table th:first-child {
  border-radius:8px 0 0 8px;
}
.attendance-tabs table td:last-child,
.attendance-tabs table th:last-child {
  border-radius: 0 8px 8px 0;
}
.attendance-tabs .table tbody td {
    border-bottom: 5px solid #323f4b;
    background: #3e4c59;
}
.attendance-tabs .table thead th, .table tfoot th {
    background: #52606d;
    border-bottom: 5px solid #323f4b;
}

.sync-signal {
    width:8px;
    height:8px;
    background: #28c76f;
    border-radius:50px;
    display: block;
    position: absolute;
    left:5px;
    top:5px;
    z-index: 999;
}
.customer-listing-area {
    padding:15px;
}  
.customer-profile-content {
    background: #3e4c59;
    border-radius: 5px;
    padding: 30px;
}
.btn-new {
    padding: 7px 12px;
    border-radius: 5px;
    line-height: normal;
}
.content-eod {
    padding: 20px;
}
.radio-selection {
    margin-bottom:10px;
}
.radio-selection [type="radio"]:checked,
.radio-selection [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radio-selection [type="radio"]:checked + label, 
.radio-selection [type="radio"]:not(:checked) + label {
    position: relative;
    padding: 10px 10px 10px 50px;
    cursor: pointer;
    line-height: 20px;
    color: #cbd2d9;
    border-radius: 5px;
    font-size: 16px;
    margin: 0;
    background: #52606d;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
}
.radio-selection [type="radio"]:checked + label {
    background:#7b8794;
    color: #ffffff;
}
/* .radio-selection [type="radio"]:checked + label:before {
    content: '';
    display: block;
    width: 10px;
    height: 18px;
    border: solid #ffffff;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    display: block;
    position: absolute;
    left: 15px;
}
.radio-selection [type="radio"]:not(:checked) + label:before {
    display: none;
} */

.radio-selection [type="radio"]:checked + label:before,
.radio-selection [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    width: 26px;
    height: 26px;
    border: 1px solid #9aa5b1;
    border-radius: 100%;
    background: #9aa5b1;
    bottom: 0;
    margin: auto;
}
.radio-selection [type="radio"]:checked + label:before {
    border: 1px solid #19d895;   
    background: #19d895;
}
.radio-selection [type="radio"]:checked + label:after {
    content: '';
    display: block;
    width: 8px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 19px;
    top: 22px;
}


#tabpending {
    background: #ea5455;
    color: #ffffff;
    animation: blinktab 1s infinite;
  }

.ngx-pagination .current {
    display: none !important;
}
  
  @keyframes blinktab {
    0% {
      opacity: 1.0;
    }
  
    50% {
      opacity: 0.0;
    }
  
    100% {
      opacity: 1.0;
    }
  }


  .dashboard-content {
    text-align: center;
    /* padding-top:8%; */
    padding-top:1%;
}
.dashboard-content h2 {
    margin-bottom: 30px;
    color: #ffffff;
}
.dashboard-content h2 span {
    display: block;
    font-size: 24px;
}
.addon-tabs-content-bg {
    background-color: #52606D;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.addon-tabs-content-bg h4 {
    font-size: 1rem;
}
.addon-tabs-content-bg h6 {
    font-size: 1rem;
    border-bottom: solid 1px #929292;
    margin-bottom: 10px;
}

.addon-tabs-content-bg  h6 .addon-heading span {
    font-weight: 400;
    border-radius: 100px;
    border: 1px solid #6D7D8B;
    background: #3E4C59;
    padding:5px 10px;
    font-size: 14px;
    margin-left: 10px;
}
.addon-tabs-content-bg  h6 .addon-heading span font {
    font-size: 12px;
    font-family: initial;
    font-style: italic;
    font-weight: 700;
    background: #ff6258;
    color: #ffffff;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
    margin-left: -5px;
}

.addon-select-box label.selected.removed,
.addon-select-box label.removed:focus,
.addon-select-box label.removed:focus-visible
.addon-select-box input[type="checkbox"]:checked+label.removed {
    border-color: #6D1212 !important;
    background: #6D1212 !important;
}

.table-items-sorting .nav-tabs .btn.selected-filter:after {
    width: 20px;
    height: 20px;
    content: "";
    background: url('/assets/images/selected-icon.png'); 
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    transform: none;
    opacity: 1;
    left: auto;
}
.sub-crus-type input[type="radio"]:checked+label {
    border-color: #19d895;
    background: #19d895;
}

.sub-crus-type label {
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    background: #3e4c59;
    margin-bottom: 10px;
    border: 1px solid #9aa5b1;
    position: relative;
}

.setting-box .hydrated {
    margin-left: 0;
    margin-right: 0;
}
.setting-box .ion-inherit-color {
    font-size: 18px;
    padding: 10px;
}
.setting-box .card-content-ios {
    padding: 10px;
    margin-top: -15px;
}
.setting-box .sc-ion-label-ios-h {
    font-size: 16px;
}
.setting-box .ion-color-primary {
    width: fit-content;
    margin-top: 15px;
}
.setting-box .button-native-wrapper {
    padding: 5px 10px 0;
    min-height: auto;
    margin: 10px auto 0px;
}
.setting-box .button-native-wrapper button {
    padding: 5px 10px;
}