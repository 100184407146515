/*Responsive*/


@media only screen and (max-width:576px) {
  body {
    font-size: 14px;
  }  

  .loginpage {
    padding:30px 30px 0px 30px;
  }
  .loginpage h1 {
    margin-bottom:1rem;
  	font-size: 1.25rem;
  }
  .h5, h5 {
    font-size: 1rem;
  }
  .h1, h1 {
    font-size: 1.25rem;
  }
  .h2, h2 {
    font-size: 1.125rem;
  }
  .login-box {
    padding: 0;
    background: transparent;
    margin:0 0 10px 0;
  }
  .powered-by-limerr,
  .support-by-limerr {
    display: none;
  }
  .form-group {
    margin-bottom: 16px;
  }
  .form-box {
    padding: 20px;
}
  .ng-select .ng-select-container {
    min-height: 46px;
  }
  .custom-grid-column {
	width: 100px;
	height: 80px;
	margin: 0 10px 10px 0px;
	font-size: 14px;
	font-weight: 400;
	padding: 0px 5px;
}
  .custom-grid-column.last_sync_time {
    /* width: 210px; */
}
  .custom-grid-column .box-icons {
    margin-bottom: 5px;
  }
  .custom-grid-column .box-icons img {
    height:25px;
  }
  .attendance-tabs .nav-tabs {
    margin: 0 0 10px 0;
    justify-content: center;
  }
  .attendance-tabs .nav-tabs .nav-item {
    margin: 0px 5px;
  }
  .attendance-tabs .nav-tabs .nav-link {
    padding: 10px 10px 10px 10px;
    width: auto;
    font-size: 14px;
  }
  .attendance-tabs .nav-tabs .nav-link span {
    margin-right: 5px;
}
  .attendance-tabs .nav-tabs .nav-link span img {
    height:20px;
  }
  .attendance-tabs .tab-content {
    padding: 20px;
  }
  .page-heading {
    margin-bottom: 1rem;
  }

  .modal-header {
    border-radius: 0px;
  }
  .modal-yes-now {
    padding:0px;
  }
  .modal-yes-now .icon {
    width: 80px;
    height: 80px;
}
  .modal-dialog {
    margin: 0;
    transform: none !important;
  }
  .modal-content {
    border-radius: 0;
    outline: 0;
    height: 100vh;
   
  }

.Mb-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Mb-modal-header,
.Mb-modal-title {
	display: block;
}
.Mb-modal-title {
    text-align: center;
    color: #ffffff;
    font-size:18px;
    margin-top: 40px;
    margin-bottom: 20px;
}
.Mb-modal-header {
	background: #616161;
	padding:40px 0px;
	position: relative;
	z-index: 9;
}
.Mb-modal-header .close {
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 20px;
  background: transparent;
}
.Mb-modal-header .modal-head-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  background: rgba(0,0,0,0.2);
  border: 5px solid #282828;
  padding: 5px;
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
}
.Mb-modal-header .modal-head-icon span {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #575757;
  display: block;
  margin: auto;
  padding:10px;
}
.calculator-keys button {
    margin: 0px 5px 10px 5px;    
}
.keypadbox {
    padding: 0px;
}
ion-footer {
  display: none;
}
.dashboard-right-actions {
	text-align: left;
}
.dashboard-right-actions .custom-grid-column {
	margin-right:10px;
}
.dashboard-right-actions > br {
	display: none;
}
.page-dashboard {
	padding-bottom: 70px;
}
.button-new-order {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	padding: 10px;
	background: #1f2933;
	z-index: 1;
}
.button-new-order .custom-grid-column {
	width: 100%;
	margin: 0;
	font-size: 14px;
	line-height: normal;
	height:50px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}
.button-new-order .custom-grid-column .box-icons {
	display: none;
}
ion-header .btn-sync {
    background: transparent;
}
.setting-tabs {
	padding-left: 0;
}
.setting-tabs .nav-tabs {
	position: static;
}
.setting-tabs .nav-tabs .nav-item {
	display: inline-block;
	margin: 0 6px 10px 0px;
	width: 100px;
}
.setting-tabs .nav-tabs .nav-link {
	padding: 10px 5px;
    font-size: 12px;
    font-weight: 400;
}
.setting-tabs .nav-tabs .nav-link span {
	width: 20px;
	margin: auto auto 5px auto;
}
.setting-box h4 {
	font-size: 18px;
}
.link-cart {
	display: block;
	text-align: right;
	position: relative;
	background: #19d895;
	padding: 17px 15px;
	line-height: 1;
	color: #ffffff;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1px;
	border-radius: 5px;
}
.link-cart span {
	color: #121212;
	font-weight: 400;
	display: block;
	text-transform: capitalize;
	float: left;
}
ion-title {
    line-height: 40px;
}
.link-search {
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 40px;
    vertical-align: top;
    position: absolute;
    right: 5px;
    top: 5px;
}
.link-search img {
    height: 30px;
}

ion-back-button {
  --color: #ffffff;
}
.modal-search-plu .modal-content .modal-body,
.modal-addons-customize .modal-content .modal-body {
	padding: 10px;
}
.page-pos-leftsidebar {
	width: 100%;
}
.back-arrow {
  display: none;
}
.button-action-grid ul {
	display: block;
}

.subcat-panel {
	padding: 0px 4px 4px 4px;
}
.page-pos-rightsidebar {
	position: static;
	width: 100%;
	padding-right: 0;
}
.product-categories {
	padding: 4px;
	position: static;
	width: 100%;
	height: auto;
	display: block;
}
.product-categories .form-row,
.product-sub-categories .form-row {
	margin-right: -2px;
	margin-left: -2px;
}
.product-categories .form-row > .col,
.product-categories .form-row > [class*="col-"],
.product-sub-categories .form-row > .col, 
.product-sub-categories .form-row > [class*="col-"] {
	padding-right: 2px;
	padding-left: 2px;
}
.custom-page-controls > div > button {
	height:60px;
}
.custom-page-controls > div.disabled > button {
	border-color: #757575;
	pointer-events: none;
	background: #757575;
}

.custom-page-controls {
    position: static;
    width: auto;
    padding-left: 0;
}

.crus-type label {
	padding: 5px 10px;
  text-align: center;
}
.crus-type h5 {
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	min-height: 20px;
	margin: 0;
}
.crus-type .item-size-price {
	font-size: 14px;
}
.sub-crus-type label {
	padding: 10px;
}
.sub-crus-type h5 {
	font-size: 14px;
	font-weight: 400;
}
.sub-crus-type .item-size-price {
	font-size: 14px;
}
.addon-heading {
	font-size: 14px;
}

.modal-title {
	line-height: 30px;
	font-size: 18px;
}
.addon-select-box label {
	padding: 5px 15px;
	height: 80px;
	line-height: 18px;
}
.btn-order-selection {
	padding: 5px 10px;
	height: 60px;
	font-size: 14px;
}
.table thead th,
.table tfoot th,
.table tbody td {
	padding: 6px;
}
.add-item-name {
	line-height: 20px;
	font-size: 12px;
}
.remove-item-name {
	line-height: 20px;
	font-size: 12px;
}
.coupon-type input[type="radio"].coupon-radio + label span, .coupon-type input[type="radio"].coupon-radio + label.clr span {
	font-size: 14px;
	font-weight: 600;
}

.page-checkout .page-pos-leftsidebar, .page-checkout .page-pos-rightsidebar {
	position: static;
	height: auto;
  background: transparent;
}
.checkout-detail {
	padding:10px;
}
.checkout-action-buttons {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	padding-bottom: 0;
}
.checkout-action-buttons .btn {
	margin-bottom: 10px;
}
.payment-type input[type="radio"] + label, 
.payment-type input[type="radio"] + label.clr {
	padding:12px 10px;
    font-size: 14px;
}
.page-checkout {
	padding-bottom:130px;
}
.selected-address {
	margin-top: 10px;
}
.payment-type input[type="radio"] + label span {
	display: none;
}
.valuebox {
	font-size: 16px;
}
.valuebox span {
	font-size: 14px;
	font-weight: 400;
	color: #c5c4ce;
}
.checkout-table-summary {
    position: static;
    border-radius: 5px;
    line-height: 24px;
}
.checkout-table-summary .subtotal {
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
}
.checkout-summary-box {
	padding: 10px;
}
.checkout-balance-bar {
	padding:0px 10px;
	background: transparent;
}
.line {
	display: none;
}	 
.btn-add, .btn-back {
    padding: 5px 15px;
    font-size: 14px;
}
.page-title {
	line-height: 36px;
	font-size: 18px;
}
.filter-selection .btn {
	font-size: 12px;
	margin-right: 5px;
	margin-bottom: 5px;
}
.checkout-detail h4 {
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
}
.calculator-keys-checkout {
	border-radius: 8px;
  overflow: hidden;
}
.calculator-keys-checkout button {
	margin: 5px;
	border: 1px solid #323f4b  !important;
}
.checkout-action-buttons .btn {
	padding: 5px 15px;
	height: 50px;
}
.checkout-action-buttons .btn span {
  display: none;
}
.checkout-action-buttons .btn > br {
  display: block;
}
.pos-customer-info {
	background: #3f3f3f;
}
.btn-order-type {
	width: auto;
    float: right;
    line-height: normal;
    padding: 9px 10px;
    font-size: 12px;
    margin:6px 50px 6px 0px;
}
.Mb-customer-info {
	position: relative;
	float: left;
	margin: 5px;
	color: #ffffff;
	left: 50px;
}
.product-item-qty {
	font-size: 12px;
	font-weight: 400;
	width: 25px;
	height: 25px;
	line-height: 25px;
}
.order-customer-name {
    font-size: 14px;
  	padding-left: 45px;
}
.product-item-price {
	font-size:13px;
}
.product-item-select {
	font-size:10px;
}
.productbox .plu_type_icon {
	height: 3px;
	width: 100%;
	left: 0;
	top: 0;
}
.nav-scroller-item {
	padding: 0 5px;
	font-size: 13px;
	line-height: 16px;
	height: 60px;
	margin: 0;
}
.nav-scroller-item.selected {
	border-color: #424242;
}
.product-sub-categories {
	padding: 4px;
}
.sub-nav-scroller {
	font-size: 12px;
	padding: 0 5px;
	height: 50px;
}
.sub-cat-controls button {
	height: 50px;
}

.custom-page-controls > div > a img,
.sub-cat-controls a img {
	height: 26px;
}
.product-subcat {
	margin: 0;
	background: transparent;
	border-color: transparent;
	color: #bdbdbd;
	height: 40px;
	padding: 0px 5px;
}
.product-subcat.selected {
	border-color: transparent;
}
.notitemfound span {
	width: 80px;
}
.pos-order-total span {
	font-size: 18px;
	font-weight: 500;
}
.coupon-type input[type="radio"].coupon-radio + label, .coupon-type input[type="radio"].coupon-radio + label.clr {
	font-size: 14px;
}
.orderlisting-tabs .nav-tabs {
	display: inline-flex;
	width: 100%;
	overflow-x: auto;
	/* border-bottom: 2px solid #DDD; */
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	flex-wrap: nowrap;
}
.tabmenu {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	background:#1f2933;
	padding: 10px;
  z-index: 999;
  display: block;
}
.tabmenu .btn {
	width: 100%;
	line-height: normal;
	font-size: 14px;
	padding: 7px 5px;
}
.pos-dashboard {
    padding-bottom: 70px;
}
.orderlisting-tabs .nav-tabs .nav-link {
	font-size: 12px;
	padding: 5px 0px;
	width: 100%;
}
.orderlisting-tabs .nav-tabs .nav-link span {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.customer-order-detail {
    font-size: 12px;
    margin-top: 4px;
    line-height: 20px;
}
.customer-details-section {
    padding: 0px;
}
.customer-details-section .nav-tabs .nav-link {
    padding: 8px 15px;
	font-size: 14px;
}
.customer-details-section .nav-tabs .nav-link span {
    margin-right: 0px;
}
.customer-details-section .nav-tabs .nav-link span img {
    height: 20px;
}
.customer-profile-content {
    padding: 20px;
}
.order-box {
	padding: 10px 10px 10px 60px;
}
.order-platform-logo {
	width: 40px;
	height: 40px;	
}
.order-platform-logo span {
	font-size: 10px;
	line-height: 42px;
	font-weight:500;
}
.payment-type-icon {
	right: 10px;
	top: 5px;
	margin: 0;
	height: 25px;
	width: 25px;
}

.pagination-next-prev {
	bottom: -70px;
	right: 5px;
}
.modal-order-detail .action-buttons .btn-action {
	font-size: 14px;
}
.notfound {
	font-size: 16px;
}
.notfound span img {
	height: 80px;
}
.paidout-expense-grid-header {
    display: none;
}
.grid-lblname h6 {
	display: block;
}
.paiout-expense-box {
    padding: 20px 20px 10px 20px;
}
.grid-lblname {
    margin-bottom: 10px;
}
.table-box-content {
	padding: 10px;
	position: relative;
	min-height: 100px;
}
.table-box .table-no {
	font-size: 16px;
}
.table-display-top .nav-tabs .btn-sync {
	width: 36px;
	height: 36px;
}
.table-display-top .nav-tabs .nav-link {
	font-size: 13px;
	line-height: 16px;
	padding: 10px 35px 10px 10px;
	font-weight: 500;
}
.table-display-top .nav-tabs .nav-link span {
	right: 5px;
}
.table-items-sorting {
	padding:0px;
	position: static;
}
.table-items-sorting ul li.mb-hide {
	display: none;
}
.table-layout-box {
	padding-top:0px;
}
.table-items-sorting ul {
	padding: 0 0 4px 0;
  justify-content: start;
  overflow-x: auto;
  white-space: nowrap;
}
.table-items-sorting ul li {
	margin-right:0px;
}
.table-items-sorting .btn {
	width: auto;
	padding: 10px;
	border-radius: 0;
}
.table-items-sorting .drp-booking-time {
	border-radius: 0;
	width: auto;
	padding: 12px 15px;
	height: 46px;
}
.table-display-top .nav-tabs {
	padding: 5px;
}
.table-display-top .nav-tabs .nav-item {
	margin-right: 5px;
}
.order-calculation-section .btn {
	padding: 8px 5px;
  font-size: 14px;
  line-height: 18px;
}
.order-calculation-section span {
	display: block;
}
.mb-hide {
  display: none;
}
.desktop-hide {
  display:block;
}
.orderdetail-header {
	background: #7b8794;
}
.cart-item-row .dropdown-menu .button-action span {
	display: none;
}
.price-over-value {
	font-size: 14px;
}
.calculator-keys button {
	font-size: 18px;
	padding: 12px 0;
}
.pos-order-total {
	bottom: 66px;
}
.button-action-grid .button-action {
	padding: 15px 5px;
	line-height: 1;
	font-size: 14px;
}
.button-action-grid .button-action span {
	display: none;
}
.cart-body {
	height: calc(100vh - 260px);	
}
.productbox {
	padding: 5px 5px;
	height: 90px;
	line-height: 20px;
}
.modal-filter-detail .modal-content {
	overflow-y: auto;
	overflow-x: hidden;
}
.filter-bottom-bar {
	position: relative;
}
.filter-by-search {
	width: 70%;
}
.filter-by-search .form-control {
	padding: 5px 60px 5px 15px;
	font-size: 14px;
}
.transaction-lookup-top .btn-filter,
.transaction-lookup-top .btn-home {
	width: 40px;
}
.order-list-statistics .pagination-next-prev {
	position: static;
}
.customer-details-section .pagination-next-prev {
	position: static;
}
.next-prev-bar {
	position: static;
	padding: 0;
}
.notaccess-features {
    padding: 10px;
    margin-top: 20%;
}
.notaccess-features h3 {
    font-size: 18px;
}
.page-end-of-day .orderlisting-content {
    height: auto;
}
.page-end-of-day .app-content {
    padding-top: 0;
}
.page-end-of-day .app-content .main-content {
	padding:0; 
}
.page-end-of-day .next-prev-bar {
	margin-top: 20px;
}
.table-items-sorting .nav-tabs .btn {
    font-size: 12px;
    padding: 3px 0px;
}
.table-items-sorting .nav-tabs .btn span {
    font-size: 16px;
}
.modal-body {
    padding: 20px;
}
.radio-selection [type="radio"]:checked + label,
.radio-selection [type="radio"]:not(:checked) + label {
    height: auto;
}
.modal-filter-detail {
    max-width: 100%;
}
.select-crust-type {
    margin-bottom: 10px;
}
.addon-tabs-content-bg {
    padding: 10px;
}
.setting-box {
    padding: 0;
	background-color: transparent;
}
.setting-tabs .hydrated {
	margin-bottom: 5px;
}
.setting-box .button-native-wrapper {
	margin: 10px auto 15px;
}
.setting-tabs .ion-inherit-color,
.setting-tabs .card-content-md {
	padding: 10px;
    padding-bottom: 0px;
	margin-bottom: 5px;
	font-size: 16px;
}
.setting-tabs .sc-ion-label-md-h {
	font-size: 14px;
}
.setting-box .button-native-wrapper button {
	margin-bottom: 0;
}


}

/* iPhone 5 Retina regardless of IOS version */
@media (device-height : 568px) 
   and (device-width : 320px) 
   and (-webkit-min-device-pixel-ratio: 2) {
    body {
		font-size: 13px;
	}
	.custom-grid-column {
		width: 90px;
		height: 70px;
		margin:0 5px 5px 0px;
		font-size: 14px;
		font-weight: 400;
		padding: 0px 5px;
	}
	.dashboard-right-actions .custom-grid-column {
		margin-right: 10px;
	}
	.form-control {
		padding: 6px 15px;
		font-size: 14px;
	}
	.btn {
		padding:8px 20px;
		font-size: 14px;
	}
	.btn-add, .btn-back {
		padding: 5px 15px;
		font-size: 14px;
	}
	.main-content {
		padding:10px;
	  }
	.form-box {
	padding: 15px;
}
label {
	font-size: 14px;
}
.order-customer-name {
	font-size: 12px;
	padding-left:30px;
}
.customer-search-icon {
	width:25px;
	height:25px;
	left: 0;
}
.Mb-customer-info {
	margin: 8px 0;
}
.btn-order-type {
	margin: 8px 10px 8px 5px;
	padding: 6px 5px;
}
.btn-order-type img {
	height: 10px;
}
.productbox .item-name {
	font-size: 14px;
}
.btn-order-selection {
	font-size: 13px;
}
.btn-dots {
	width: 24px;
}
.modal-body {
	padding: 15px;
}
.calculator-keys button {
	font-size: 16px;
	padding: 8px 0;
}
.all-clear img {
	height: 16px;
}
.checkout-action-buttons .btn {
	padding: 5px 5px;
}
.checkout-detail h4 {
	font-size: 14px;
}
.orderlisting-tabs .nav-tabs .nav-item {
	margin: 4px 2px;
}
.orderlisting-tabs .nav-tabs {
	padding: 0px 2px;
}
.table-items-sorting .btn {
	padding: 5px 10px;
	font-size: 12px;
}
.table-items-sorting .drp-booking-time {
	padding: 12px 10px;
	height: 36px;
}
.table-display-top .nav-tabs .nav-link span {
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 10px;
}
.table-display-top .nav-tabs .nav-link {
	font-size: 12px;
	padding: 8px 30px 8px 10px;
}
.table-box-content {
	padding: 5px;
	min-height: 90px;
}
.table-merge {
	bottom: 10px;
	left: 5px;
}


	
}

@media only screen and (min-width:1024px) and (max-width: 1366px) {
	
	.nav-scroller-item {
		height: 50px;
	}
	.sub-cat-controls button {
		height:50px;
	}

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    body {
    font-size: 14px;
}
.product-categories {
    width: 150px;
}
.page-pos-rightsidebar {
    padding-right: 150px;
}
.login-box {
padding: 30px;
}
.last_sync_time {
/* width: 275px; */
}
.main-content {
    padding: 20px;
}
.sub-nav-scroller {
    font-size: 13px;
    padding: 0 5px;
}
.productbox .item-name {
    font-size: 14px;
}
.productbox {
    height: 100px;
}




}

@media  screen and (max-width: 1366px) {

	.pagination-next-prev {
		position: static;
	}
	.next-prev-bar {
		position: static;
		padding: 10px 0 20px 0;
	}
	
}








