/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";



/*! Firefox */    
html{
    scrollbar-color: #8b8b8b #121212;
}

/* width */
::-webkit-scrollbar {
    width:6px;
}

/* button */
::-webkit-scrollbar-button {
    background: #8b8b8b;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8b8b8b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8b8b8b;
}

/* Track */
::-webkit-scrollbar-track {
    background: #121212;
}

/* The track NOT covered by the handle.
::-webkit-scrollbar-track-piece {
    background: #121212;
}

/* Corner */
::-webkit-scrollbar-corner {
    background: #999;
}

/* Resizer */
::-webkit-resizer {
    background: #121212;
}


html.md {
	--ion-default-font:'Roboto', sans-serif
}

ion-content {
    --background:#323f4b;
    --color: #cbd2d9;
	--ion-font-family:'Roboto', sans-serif
}
.calculator-keys { 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin:0px;
}
.calculator-keys button {
    margin: 0px 5px 10px 5px;
    border-radius: 8px;
    color: #fff;
    font-weight: 400;
    border: 0px;
    font-size: 24px;
    height: auto;
    outline: 0;
    box-shadow: none;
    padding: 15px 0;
    background: #1f2933;
}
.calculator-keys button.all-clear {
background-color: #f0595f;
border-color: #f0595f;
color: #fff;
}










 
html[dir=rtl] {

text-align: right;

ion-content {
    --ion-font-family: "Cairo", sans-serif;
}
body {
    font-family: 'Cairo', sans-serif;
    font-size: 15px;
    text-align: right;
    direction: rtl;
}
.text-right {
    text-align: left !important;
}
.text-left {
    text-align: right !important;
}
.dashboard-right-actions, .button-new-order {
    text-align: left;
}
.product-categories {
    left: 0;
    right: auto;
}
.page-pos-leftsidebar {
    right: 0;
    left: auto;
}
.page-pos-rightsidebar {
    padding-left: 180px;
    padding-right: 0;
    left: 0;
    right: auto;
}
.customer-search-icon {
    right: 10px;
    left: auto;
}
.order-customer-name {
    padding-right: 50px;
    padding-left: 0;
    line-height: inherit;
}
.checkout-table-summary .summary-value {
    text-align: left;
}
.page-checkout .page-pos-rightsidebar {
    padding-left: 0;
}
.payment-type input[type=radio] {
    right: -1000px;
    left: auto;
}
.ml-auto, .mx-auto {
    margin-right: auto!important;
    margin-left: 0 !important;
}
.orderlisting-tabs .nav-tabs .nav-link span {
    margin-bottom: 4px;
    line-height: 16px;
}
.custom-grid .custom-grid-column {
    line-height: inherit;
    margin: 0 0px 15px 15px;
}
.modal-header .close {
    left: 0;
    right: auto;
}
.table-items-sorting .nav-tabs .btn span {
    margin-bottom: 4px;
    line-height: 16px;
}
.table-items-sorting .drp-booking-time {
    padding: 16px 10px;
}
.table-items-sorting ul li {
    margin-left: 10px;
    margin-right: 0;
}
.orderlisting-tabs .nav-tabs .nav-item {
    margin-left: 10px;
    margin-right: 0;
}
.orderlisting-tabs .nav-tabs .btn-filter img {
    height: 12px;
}
.table-box .tickmark {
    left: 10px;
    right: auto;
}
.attendance-tabs table td:first-child, 
.attendance-tabs table th:first-child {
    border-radius:0px 8px 8px 0px;
}
.attendance-tabs table td:last-child, 
.attendance-tabs table th:last-child {
    border-radius: 8px 0px 0px 8px;
}
.ml-2, .mx-2 {
    margin-right: .5rem!important;
    margin-left: 0 !important;
}
.order-box {
    padding: 18px 90px 18px 60px;
}
.payment-type-icon {
    left: 10px;
    right: auto;
}
.order-platform-logo {
    right: 10px;
    left: auto;
}
.modal-order-detail .order-basic-information {
    line-height: inherit;
}
.rightlinks {
    float: left;
}
.store-logo {
    float: right;
}
.btn-dots {
    vertical-align: top;
}
.btn-remove {
    margin: 6px 0px;
}
.column-qty {
    width: 60px;
}
.pos-order-total {
    line-height: inherit;
}
.sync-signal {
    right: 5px;
    left: auto;
}
.calculator-keys {
    direction: ltr;
}
.modal.show .modal-order-detail {
    left: 0;
    right: auto;
}
.modal-order-detail {
    left: -600px;
    right: auto;
}
.valuebox {
    line-height: inherit;
}
.customer-details-section .nav-tabs .nav-link span {
    margin-left: 10px;
    margin-right: 0;
}
.filter-by-search .btn-search {
    left: 0;
    right: auto;
}
.filter-by-search .form-control {
    padding: 10px 20px 10px 60px;
}
.customer-address-box .address span,
.customer-address-box .phone span {
    right: 0;
    left: auto;
}
.customer-address-box .address, .customer-address-box .phone {
    padding-right: 25px;
    padding-left: 0;
}
.address-action-buttons button {
    margin-left: 15px;
    margin-right: 0px;
}
.transaction-lookup-top .btn-home {
    margin-right: auto;
    margin-left: 0;
}
.ml-3, .mx-3 {
    margin-right: 1rem!important;
    margin-left: 0 !important;
}
.modal-order-detail button.close {
    right: -60px;
    left: auto;
}
.modal.show .modal-filter-detail {
    left: auto;
    right: 0;
}
.modal-filter-detail button.close {
    left: -60px;
    right: auto;
}
.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.customer-searchbox .btn-customer-search {
    left: 0;
    right: auto !important;
}
.customer-basic-information .arrow-more {
    left: 10px;
    right: auto;
    transform: rotate(-180deg);
}
.paylater-leftside {
    right: 0;
    left: auto !important;
}
.paylater-rightside {
    left: 0;
    right: auto !important;
}
.custom-page-controls>div>button img {
    transform: rotate(-180deg);
}
.float-right {
    float: left!important;
}
.modal-order-detail .action-buttons .btn-action {
    margin-right: 10px;
    margin-left: 0;
}
.setting-tabs .nav-tabs {
    right: 0;
    left: auto;
}
.setting-tabs {
    padding-right: 150px;
    padding-left: 0;
}
.radio-selection [type="radio"]:checked + label, .radio-selection [type="radio"]:not(:checked) + label {
    padding: 10px 50px 10px 10px;
}
.radio-selection [type="radio"]:checked + label:before, 
.radio-selection [type="radio"]:not(:checked) + label:before {
    right: 10px;
    left: auto;
}
.radio-selection [type="radio"]:checked + label:after {
    right: 19px;
    left: auto;
}
.attendance-tabs .nav-tabs .nav-item {
    margin: 0px 0px 0px 20px;
}
.coupon-code-strip .remove-coupon {
    left: 0;
    right: auto;
}
.coupon-code-amount {
    left: 30px;
    right: auto;
}
.sub-cat-controls button img {
    transform: rotate(-180deg);
}
.calculator-keys button {
    font-size: 20px;
}
.payment-type input[type=radio]+label, .payment-type input[type=radio]+label.clr {
    padding: 12px 15px;
}
.radio-selection [type=radio]:checked, .radio-selection [type=radio]:not(:checked) {
    right: -9999px;
    left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
.page-pos-rightsidebar {
    padding-left: 150px;
}


}


}



