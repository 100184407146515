/*\
|*| ========================================================================
|*| Bootstrap Toggle: bootstrap4-toggle.css v3.6.1
|*| https://gitbrent.github.io/bootstrap4-toggle/
|*| ========================================================================
|*| Copyright 2018-2019 Brent Ely
|*| Licensed under MIT
|*| ========================================================================
\*/
.btn-group-xs > .btn,
.btn-xs {
    padding: 0.35rem 0.4rem 0.25rem 0.4rem;
    font-size: 12px;
    line-height: 0.8;
    border-radius: 0.2rem;
}
.checkbox label .toggle,
.checkbox-inline .toggle {
    margin-left: -1.25rem;
    margin-right: 0.35rem;
}
.toggle {
    position: relative;
    overflow: hidden;
}
.toggle.btn.btn-light,
.toggle.btn.btn-outline-light {
    border-color: #555;
}
.toggle input[type="checkbox"] {
    display: none;
}
.toggle-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left 0.35s;
    -webkit-transition: left 0.35s;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.toggle-group label,
.toggle-group span {
    cursor: pointer;
}
.toggle.off .toggle-group {
    left: -100%;
}
.toggle-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
}
.toggle-off {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	background: #555;
	color: #ffffff;
}
.toggle-handle {
	position: relative;
	width: 16px;
	border-width: 0;
	background-color: #ffffff;
	border-radius: 50%;
	height: 16px;
	right: 12px;
	top:2px;
}
.toggle.btn-outline-primary .toggle-handle {
    background-color: var(--primary);
    border-color: var(--primary);
}
.toggle.btn-outline-secondary .toggle-handle {
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.toggle.btn-outline-success .toggle-handle {
    background-color: var(--success);
    border-color: var(--success);
}
.toggle.btn-outline-danger .toggle-handle {
    background-color: var(--danger);
    border-color: var(--danger);
}
.toggle.btn-outline-warning .toggle-handle {
    background-color: var(--warning);
    border-color: var(--warning);
}
.toggle.btn-outline-info .toggle-handle {
    background-color: var(--info);
    border-color: var(--info);
}
.toggle.btn-outline-light .toggle-handle {
    background-color: var(--light);
    border-color: var(--light);
}
.toggle.btn-outline-dark .toggle-handle {
    background-color: var(--dark);
    border-color: var(--dark);
}
.toggle[class*="btn-outline"]:hover .toggle-handle {
    background-color: var(--light);
    opacity: 0.5;
}
.toggle.btn {
    min-width: 3.7rem;
    min-height: 2.15rem;
    border-radius: 50px;
}
.toggle-on.btn {
    padding-right: 1.5rem;
}
.toggle-off.btn {
    padding-left: 1.5rem;
}
.toggle.btn-lg {
    min-width: 5rem;
    min-height: 2.815rem;
}
.toggle-on.btn-lg {
    padding-right: 2rem;
}
.toggle-off.btn-lg {
    padding-left: 2rem;
}
.toggle-handle.btn-lg {
    width: 2.5rem;
}
.toggle.btn-sm {
    min-width: 80px;
    min-height: 30px;
}
.toggle-on.btn-sm {
	padding-right: 30px;
}
.toggle-off.btn-sm {
    padding-left: 30px;
}
.toggle.btn-xs {
    min-width: 2.19rem;
    min-height: 1.375rem;
}
.toggle-on.btn-xs {
    padding-right:1rem;
}
.toggle-off.btn-xs {
    padding-left:1rem;
}

.toggle.off .toggle-group .toggle-handle {
	right: auto;
	left: 12px;
}

